<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
-->

<template>
  <b-container fluid class="mt-3">
    <b-row class="track-header">
      <b-col class="align-bottom">
        <div class="my-2">
          <b-badge pill variant="primary" class="mr-1">{{ metaData.name }}</b-badge>
          <b-badge pill class="mx-1">{{ driver.alias }}</b-badge>
        </div>
      </b-col>
      <b-col class="my-2 text-center" size="sm">
        <b-badge v-if="clientHealth" pill :variant="clientHealth.variant">{{ clientHealth.message }}</b-badge>
        <b-badge
          class="mx-1"
          pill
          :variant="clientStatus.toLowerCase().includes('error') ? 'danger' : clientStatus.toLowerCase().includes('unknown') ? 'secondary' : 'success'"
          v-if="clientStatus && !dragXtoTime && !hoveredEvent"
        >
          {{ clientStatus }}
        </b-badge>
        <span v-if="dragXtoTime">
          <b-badge pill variant="primary"><b-icon-clock></b-icon-clock> {{ dragXtoTime }}</b-badge>
        </span>
        <b-badge pill variant="primary" v-if="hoveredEvent && !dragXtoTime">
          {{ $t('ems.timeline.track.event') }}: {{ hoveredEvent.method }}
        </b-badge>
      </b-col>
      <b-col class="text-right">
        <div v-if="metaData.shortcut_list" class="d-inline-flex">
          <b-button v-for="method in metaData.shortcut_list" :key="method"
                    class="m-1" size="sm" variant="primary"
                    @click="$root.$emit('addEvent-shortcut', method, metaData.client_uuid, metaData.uuid, centerTime)">
            <b-icon-plus></b-icon-plus> {{ method }}
          </b-button>
        </div>
        <b-button class="m-1" size="sm" variant="dark"
                  @click="$root.$emit('addEvent', metaData)">
          <b-icon-plus></b-icon-plus> {{ $t('ems.timeline.track.event') }}
        </b-button>
        <b-dropdown class="m-1" size="sm" variant="secondary" right>
          <template slot="button-content">
            <b-icon-gear-wide-connected></b-icon-gear-wide-connected>
          </template>
          <b-dropdown-item @click="$root.$emit('editTrack', metaData)">
            {{ $t('ems.timeline.track.editTrack') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="!metaData.enabled"
                           @click="metaData['enabled'] = true;
                           $root.$emit('enableTrack', metaData)">
            {{ $t('ems.timeline.track.enableTrack') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="metaData.enabled" disabled
                           @click="makeToast('Not implemented yet!', 'warning')">
            {{ $t('ems.timeline.track.disableTrack') }}
          </b-dropdown-item>
          <b-dropdown-item variant="danger"
                           @click="$root.$emit('deleteTrack', metaData.uuid)">
            {{ $t('ems.timeline.track.deleteTrack') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- Time view timeline -->
    <div class="row track-wrap">
      <div v-if="loading" class="loading-wrap">
        <b-spinner class="mr-2" :label="$t('ems.common.loadingWait')"></b-spinner> {{ $t('ems.common.loadingWait') }}</div>
      <div v-if="!trackEditEnabled && !loading" class="zoom-lock" onselectstart="return false">
        <span>
          <b-icon-lock-fill class="mr-2"></b-icon-lock-fill> {{ $t('ems.timeline.track.editHint') }}
        </span>
      </div>
      <svg class="track" ref="svg" width="100%" height="100"></svg>
    </div>
  </b-container>
</template>

<script>
  import Vue from 'vue'
  import Event from '@/js/event.js'
  import D3Event from '@/js/d3event.js'
  import * as d3 from 'd3'
  import moment from 'moment'
  import uuid4 from 'uuid4'
  import { timeToSeconds } from '../js/utils'

  let EVENT_HEIGHT = 68
  let TIMESCALE_MT = 25
  let MIN_SCALE_ZOOM = 1
  let MAX_SCALE_ZOOM = 500

  export default {
    name: 'timeline-track',
    props: {
      view: {},
      metaData: {},
      driver: {},
      dateContext: {},
      working_hours: {},
      clientStatus: {
        default: null
      },
      clientHealth: {
        default: null
      }
    },
    components: {},
    data () {
      return {
        events: [],
        nowIndication: null,
        visibleScale: null,
        eventUiActive: false,
        zoomScale: null,
        loading: true,
        dragXtoTime: null,
        hoveredEvent: null,
        xAxis: null,
        scatter: null,
        x: null,
        tx: null,
        zoom: false,
        hovered: false
      }
    },
    watch: {
      dateContext () {
        this.clearD3Scale()
        this.setupD3Scale().then(() => {
          this.getEvents().then(res => {
            this.events = []
            // store events
            res.msg.result.forEach(e => {
              this.events.push(new Event(e))
            })
            this.clearD3Events()
            this.getDataSet().then(res => {
              this.clearD3Events()
              this.updateD3Diffs(res)
              this.addD3Events(res).then(() => {})
              if (!this.zoomScale) {
                this.setInitZoom()
              } else {
                this.syncZoom(this.zoomScale, 0)
              }
            })
          })
        })
      },
      zoomScale () {
        this.updateD3EventBoundingBoxes()
      }
    },
    computed: {
      trackEditEnabled () {
        return this.$parent.$parent.track_edit_enabled
      },
      altKeyPressed () {
        return this.$parent.$parent.alt_key_pressed
      },
      trackSvg () {
        return d3.select(this.$refs.svg)
      },
      trackSize () {
        return {
          x: parseInt(this.trackSvg.style('width')),
          y: parseInt(this.trackSvg.style('height'))
        }
      },
      initZoom () {
        let s = timeToSeconds(this.$props.working_hours.start)
        let e = timeToSeconds(this.$props.working_hours.end)
        let dur = (moment.duration(e - s, 'seconds').hours())
        return 24 / dur
      },
      zoomEnabled () {
        return this.view === 'day'
      },
      timeContextBegin () {
        return this.view === 'day'
          ? moment(this.dateContext.startOf('day'))
          : moment(this.dateContext.startOf('isoWeek'))
      },
      timeContextEnd () {
        return this.view === 'day'
          ? moment(this.dateContext.endOf('day'))
          : moment(this.dateContext.endOf('isoWeek'))
      },
      isSnapShotView () {
        let deny = this.$parent.$parent.is_snapshot_view
        if (deny) {
          this.$root.$emit('restore-dialog')
        }
        return deny
      },
      centerTime () {
        let time = null
        if (this.tx) {
          let diff = moment(this.tx.domain()[1]).diff(moment(this.tx.domain()[0]), 'h')
          time = moment(this.tx.domain()[0]).add(diff / 2, 'h')
        }
        return time
      }
    },
    methods: {

      // === scope utils =======================================================

      setD3EventBoxWidth (d, scale) {
        // const xScale = this.tx ? this.tx : this.x
        if (d.hasEventBox) {
          // condition needed because of
          // <rect> attribute width: A negative value is not valid.
          return scale(d.nextEnd) - scale(d.begin) > 0 ? scale(d.nextEnd) - scale(d.begin) : 1
        }
        return 1
      },

      setD3EventVisible (d) {
        const xScale = this.tx ? this.tx : this.x
        d.visible = d.begin.isBetween(xScale.domain()[0], xScale.domain()[1])
        return d.d3visibleState
      },

      getDataSet () {
        return new Promise((resolve) => {
          let d3DataSet = []
          this.clearD3Events()
          this.events.forEach(e => {
            let ruleSetEvents = e.getRuleSetEvents(
              this.timeContextBegin,
              moment(this.timeContextEnd).add(1, 'hour')) // expend the range a bit

            if (ruleSetEvents) {
              ruleSetEvents.forEach(date => {
                let d3event = new D3Event(e, date)
                e.addD3Event(d3event)
                d3DataSet.push(d3event)
              })
            } else {
              let d3event = new D3Event(e, e.begin)
              e.addD3Event(d3event)
              d3DataSet.push(d3event)
            }
          })
          resolve(d3DataSet)
        })
      },

      updateD3Diffs () {
        // function sets time diffs to linked events
        this.events.forEach(e => {
          let next = this.events.find(obj => {
            return obj.uuid === e.nextRef
          })

          let prev = this.events.find(obj => {
            return obj.uuid === e.prevRef
          })

          if (e.nextRef) {
            e.nextDiff = next.begin.diff(e.begin)
          }

          if (e.prevRef) {
            e.prevDiff = e.begin.diff(prev.begin)
          }
        })
      },

      updateD3Events () {
        this.scatter
          .selectAll('.event')
          .attr('transform', d => {
            return ('translate(' + this.tx(d.begin) + ',' + TIMESCALE_MT + ')')
          })
          .selectAll('.event-box')
          .attr('width', d_ => {
            return this.setD3EventBoxWidth(d_, this.tx)
          })
        this.updateD3EventBoundingBoxes()
      },

      updateD3EventBoundingBoxes () {
        this.scatter
          .selectAll('.bounding-box-right')
          .attr('width', d => {
            let w = this.tx.range()[1] - this.tx(d.begin)
            return w > 0 ? w : 0
          })
        this.scatter
          .selectAll('.bounding-box-left')
          .attr('width', d => {
            let w = this.tx(d.begin) - this.tx.range()[0]
            return w > 0 ? w : 0
          })
          .attr('x', d => {
            return -(this.tx(d.begin) - this.tx.range()[0])
          })
      },

      updateTrack () {
        this.getEvents()
          .then(res => {
            this.events = []
            // store events
            res.msg.result.forEach(e => {
              this.events.push(new Event(e))
            })
            this.clearD3Events()
            this.getDataSet().then(res => {
              this.updateD3Diffs(res)
              this.addD3Events(res).then(() => {})
            })
          })
      },

      makeToast (msg, variant = null) {
        this.$bvToast.toast([msg], {
          title: 'TimelineAPI response',
          solid: true,
          autoHideDelay: 3500,
          toaster: 'b-toaster-bottom-right',
          variant: variant
        })
      },

      parseResponse (res) {
        if (res.msg['revision_changed'] || (res.msg['revision'] !== this.$route.params.rev)) {
          this.$router.push({
            name: 'timelineDetails',
            params: {
              cal_uuid: this.$route.params.cal_uuid,
              rev: res.msg['revision']
            }
          }).catch(() => {
          })
        }
      },

      // === API calls =========================================================

      getEvents () {
        this.loading = true
        return new Promise((resolve) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB + '/tracks/' + this.metaData.uuid + '/events/rev/' + this.$route.params.rev,
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              this.loading = false
              resolve(response)
            })
        })
      },

      updateEvent (event) {
        let payload = {
          uuid: event.uuid,
          begin: event.begin,
          method: event.method,
          args: event.args,
          args_mixins: event.argsMixins,
          rrules: event.rruleSet ? event.rruleSet.toString() : null,
          next_ref: event.nextRef,
          prev_ref: event.prevRef,
          group: event.group
        }
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/events/update', {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader(),
            body: JSON.stringify(payload)
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Event updated')
              this.parseResponse(response)
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' Event not updated', 'danger')
              reject(error)
            })
        })
      },

      deleteEvent (event) {
        return new Promise((resolve, reject) => {
          fetch(Vue.prototype.$TIMELINE_MONGODB + '/events/delete/' + event.uuid, {
            method: 'POST',
            headers: this.$keycloakmanager.getTokenHeader()
          })
            .then((resp) => resp.json())
            .then((response) => {
              this.makeToast(response.msg.status + ' Event deleted')
              this.parseResponse(response)
              resolve(response)
            })
            .catch((error) => {
              console.error('Error:', error)
              this.makeToast(error + ' Event not deleted', 'danger')
              reject(error)
            })
        })
      },

      // === D3 ================================================================

      clearD3Scale () {
        this.trackSvg
          .select('#track-scale').remove()
        this.xAxis = null
        this.scatter = null
        this.x = null
        this.tx = null
        this.zoom = null
      },

      setupD3Scale () {
        return new Promise((resolve) => {
          let width = this.trackSize.x
          let height = this.trackSize.y

          this.x = d3.scaleTime()
            .domain([this.timeContextBegin.valueOf(), this.timeContextEnd.valueOf()])
            .range([0, this.trackSize.x])
            .nice()

          this.xAxis = this.trackSvg.append('g')
            .attr('id', 'track-scale')
            .attr('transform', 'translate(0,' + 0 + ')')
            .call(d3.axisBottom(this.x))

          // Add a clipPath: everything out of this area won't be drawn.
          this.trackSvg.append('defs').append('SVG:clipPath')
            .attr('id', 'clip')
            .append('SVG:rect')
            .attr('width', width)
            .attr('height', height)
            .attr('x', 0)
            .attr('y', 0)

          // Create the scatter variable: where both the circles and the brush take place
          this.scatter = this.trackSvg.append('g')
            .attr('clip-path', 'url(#clip)')
          // create zoom behaviour
          this.zoom = d3.zoom()
            .scaleExtent([MIN_SCALE_ZOOM, MAX_SCALE_ZOOM]) // This control how much you can unzoom (x0.5) and zoom (x20)
            .extent([[0, 0], [this.trackSize.x, this.trackSize.y]])
            .translateExtent([[0, 0], [this.trackSize.x, 0]])
            .on('zoom', this.onD3ScaleZoom)

          // call zoom
          this.trackSvg.call(this.zoom)

          // This add an invisible rect on top of the chart area. This rect can recover pointer events:
          // necessary to understand when the user zoom
          this.trackSvg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .style('fill', 'none')

          if (!this.zoomEnabled) {
            this.tx = this.x
          }
          resolve()
        })
      },

      onD3ScaleZoom (d3event) {
        if (!this.zoomEnabled) return
        // recover the new scale
        this.zoomScale = d3event.transform
        this.tx = d3event.transform.rescaleX(this.x)
        // update axes with these new boundaries
        this.xAxis
          .call(d3.axisBottom(this.tx))

        this.scatter
          .selectAll('.event')
          .style('visibility', d => {
            return this.setD3EventVisible(d)
          })
          .attr('transform', (d) => {
            return ('translate(' + this.tx(d.begin) + ',' + TIMESCALE_MT + ')')
          })

          .selectAll('.event-box')
          .attr('width', d_ => {
            return this.setD3EventBoxWidth(d_, this.tx)
          })
      },

      setInitZoom () {
        let s = timeToSeconds(this.$props.working_hours.start)
        let target = moment(this.timeContextBegin).add(s, 'seconds')
        let transform = d3.zoomIdentity.scale(this.initZoom).translate(-this.x(target), 0)
        this.syncZoom(transform, 0)
      },

      syncZoom (zoomScale, dur = 250) {
        if (!this.zoomEnabled) return
        this.zoomScale = zoomScale
        this.tx = zoomScale.rescaleX(this.x)
        this.xAxis
          .transition()
          .duration(dur)
          .call(d3.axisBottom(this.tx))
          .on('end', () => {
            this.trackSvg.call(this.zoom.transform, zoomScale)
          })
        this.scatter
          .selectAll('.event')
          .transition()
          .duration(dur)
          .style('visibility', d => {
            return this.setD3EventVisible(d)
          })
          .attr('transform', d => {
            return ('translate(' + this.tx(d.begin) + ',' + TIMESCALE_MT + ')')
          })
          .selectAll('.event-box')
          .attr('width', d_ => {
            return this.setD3EventBoxWidth(d_, this.tx)
          })
          .on('end', () => {
            this.trackSvg.call(this.zoom.transform, zoomScale)
          })
      },

      clearD3Events () {
        this.trackSvg.selectAll('.event')
          .each(function (d) {
            this.remove()
          })
      },

      deleteD3Event (e) {
        this.trackSvg.selectAll('.event')
          .each(function (d) {
            if (d.uuid === e.uuid) {
              this.remove()
            }
          })
      },

      addD3Events (dataSet) {
        return new Promise((resolve) => {
          this.trackSvg.append('defs')
            .append('pattern')
            .attr('id', 'diagonalHatch')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 10)
            .attr('height', 10)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2')
            .attr('stroke', '#D496A7')
            .attr('stroke-width', 3)

          const xScale = this.tx ? this.tx : this.x
          let app = this

          let track = this.scatter
            .selectAll()
            .data(dataSet)
            .enter()

          let node = track.append('g')
            .attr('class', 'event')
            .attr('event-id', d => {
              return d.event.metaData.uuid
            })
            .attr('group-id', d => {
              return d.event.metaData.group
            })
            .style('visibility', d => {
              return this.setD3EventVisible(d)
            })
            .attr('transform', d => {
              return ('translate(' + xScale(d.begin) + ',' +
                TIMESCALE_MT + ')')
            })
            .on('mouseover', showEventUI)
            .on('mouseout', hideEventUI)
            .call(d3.drag()
              .on('start', onDragStart)
              .on('drag', onSingleDrag)
              .on('end', onSingleDragEnd))

          node.filter(d => {
            return d.hasRightBoundingBox(this.x.domain()[1])
          }).append('rect')
            .attr('class', 'bounding-box-right')
            .attr('fill', 'url(#diagonalHatch)')
            .attr('height', EVENT_HEIGHT)
            .attr('width', d => {
              let w = xScale.range()[1] - xScale(d.begin)
              return w > 0 ? w : 0
            })
            .call(d3.drag()
              .on('start', onDragStart)
              .on('drag', onGroupDrag)
              .on('end', onGroupDragEnd))

          node.filter(d => {
            return d.hasLeftBoundingBox(this.x.domain()[0])
          }).append('rect')
            .attr('class', 'bounding-box-left')
            .attr('height', EVENT_HEIGHT)
            .attr('fill', 'url(#diagonalHatch)')
            .attr('width', d => {
              return xScale(d.begin) - xScale.range()[0]
            })
            .attr('x', d => {
              return -(xScale(d.begin) - xScale.range()[0])
            })
            .call(d3.drag()
              .on('start', onDragStart)
              .on('drag', onGroupDrag)
              .on('end', onGroupDragEnd))

          // event group needs rect to make it visible
          node.filter(d => {
            return d.hasEventBox && !d.hasLeftBoundingBox(this.x.domain()[0]) && !d.hasRightBoundingBox(this.x.domain()[1])
          }).append('rect')
            .attr('class', 'event-box')
            .attr('height', EVENT_HEIGHT)
            .attr('fill', d => {
              return d.hasEventBox ? d.event.uiColor : '#000000'
            })
            .attr('width', d => {
              return this.setD3EventBoxWidth(d, xScale)
            })
            .attr('fill-opacity', d => {
              return d.hasEventBox ? 0.5 : 1
            })
            .call(d3.drag()
              .on('start', onDragStart)
              .on('drag', onGroupDrag)
              .on('end', onGroupDragEnd))

          node.append('circle')
            .attr('cx', 0.5)
            .attr('cy', 68)
            .attr('r', 4)
            .style('cursor', 'pointer')

          node.append('rect')
            .attr('height', EVENT_HEIGHT)
            .attr('fill', '#000000')
            .attr('width', 1)

          // ui group
          let ui = node.append('g')
            .attr('class', 'event-box-ui')
            .attr('opacity', 0)

          ui.append('rect')
            .attr('width', 15)
            .attr('height', 16)
            .attr('z-index', '-1')
            .style('cursor', 'pointer')
            .on('mousedown', (d3, e) => {
              d3.stopPropagation()
              this.isSnapShotView
                ? this.$root.$emit('restore-dialog')
                : onDelete(e)
            })

          ui.append('text')
            .attr('x', 4)
            .attr('y', 12)
            .style('fill', '#ffffff')
            .style('pointer-events', 'none')
            .attr('font-size', '11px')
            .text('X')

          ui.append('rect')
            .attr('y', 17)
            .attr('width', 15)
            .attr('height', 16)
            .attr('z-index', '-1')
            .style('cursor', 'pointer')
            .on('mousedown', (d3, e) => {
              d3.stopPropagation()
              this.isSnapShotView
                ? this.$root.$emit('restore-dialog')
                : this.$root.$emit('editEvent', e.event)
            })

          ui.append('text')
            .attr('x', 4)
            .attr('y', 29)
            .style('fill', '#ffffff')
            .style('pointer-events', 'none')
            .attr('font-size', '11px')
            .text('E')

          // asset link
          ui.filter(d => {
            return d.event.hasCmsArgs
          }).append('rect')
            .attr('y', 34)
            .attr('width', 15)
            .attr('height', 16)
            .attr('z-index', '-1')
            .style('cursor', 'pointer')
            .on('mousedown', (d3, e) => {
              d3.stopPropagation()
              window.open(e.event.cmsLink, '_blank')
            })
          ui.filter(d => {
            return d.event.hasCmsArgs
          }).append('text')
            .attr('x', 4)
            .attr('y', 46)
            .style('fill', '#ffffff')
            .style('pointer-events', 'none')
            .attr('font-size', '11px')
            .text('L')

          function showEventUI (_, e) {
            app.hoveredEvent = e.event
            clearTimeout(e.uiTimer)
            // show uis for all events with same event-id
            d3.selectAll("[event-id='" + e.event.uuid + "']")
              .select('g.event-box-ui')
              .attr('opacity', 1)
              .style('display', 'inline')
            // show uis for all events with same group-id
            if (e.group) {
              d3.selectAll("[group-id='" + e.event.group + "']")
                .select('g.event-box-ui')
                .attr('opacity', 1)
                .style('display', 'inline')
            }
          }

          function hideEventUI (_, e) {
            app.hoveredEvent = null
            e.uiTimer = setTimeout(() => {
              d3.selectAll("[event-id='" + e.event.uuid + "']")
                .select('g.event-box-ui')
                .style('display', 'none')

              if (e.group) {
                d3.selectAll("[group-id='" + e.event.group + "']")
                  .select('g.event-box-ui')
                  .style('display', 'none')
              }
            }, 1000)
          }

          function onDragStart (d3event, obj) {
            d3event.sourceEvent.stopPropagation()
            if (!app.isSnapShotView && !app.eventUiActive) {
              obj.dragOffet = app.tx(app.tx.invert(d3event.x))
            }
          }

          function onSingleDrag (d3event, obj) {
            if (!app.isSnapShotView && !app.eventUiActive) {
              d3.select(this).attr('transform', () => {
                return ('translate(' + d3event.x + ',' + TIMESCALE_MT + ')')
              })
            }
            let x = app.tx(obj.begin) + (d3event.x - obj.dragOffet)
            app.dragXtoTime = moment(app.tx.invert(x)).format('HH:mm:ss')
          }

          function onGroupDrag (d3event, obj) {
            if (!app.isSnapShotView && !app.eventUiActive) {
              d3.select(this).attr('transform', () => {
                return ('translate(' + (d3event.x - obj.dragOffet) + ',0)')
              })
              // needed on dragend
              obj.translate_x_cache = d3event.x - obj.dragOffet
              // display new x
              let x = app.tx(obj.begin) + obj.translate_x_cache
              app.dragXtoTime = moment(app.tx.invert(x)).format('HH:mm:ss')
            }
          }

          function onSingleDragEnd (d3event, obj) {
            if (app.isSnapShotView) {
              return
            }
            app.dragXtoTime = null
            app.altKeyPressed ? duplicationEvent(d3event, obj) : setEventPos(d3event, obj)
          }

          function duplicationEvent (d3event, obj) {
            if (!obj.group) {
              let diff = obj.getTimeDiff(app.tx.invert(d3event.x))
              obj.event.addTimeDiff(diff)
              app.$root.$emit('copyEvent', obj.event.copy())
            } else {
              app.makeToast('Event is part of a group. Select the group to copy!', 'danger')
              app.updateD3Events()
            }
          }

          function setEventPos (d3event, obj) {
            if (obj.event.durationFixed && obj.group) {
              app.makeToast('Event has fixed duration, select group to move event', 'warning')
              app.updateD3Events()
              return
            }

            if (obj.prevBegin) {
              let abort = false
              app.events.forEach(_e => {
                if (_e.uuid === obj.event.prevRef && _e.durationFixed) {
                  app.makeToast('Connected event has fixed duration', 'warning')
                  abort = true
                }
              })
              if (abort) {
                app.updateD3Events()
                return
              }
            }
            if (obj.prevBegin && d3event.x < app.tx(obj.prevBegin)) {
              app.makeToast('Event overlaps the other...', 'warning')
              app.updateD3Events()
              return
            } else if (obj.nextEnd && d3event.x > app.tx(obj.nextEnd)) {
              app.makeToast('Event overlaps the other...', 'warning')
              app.updateD3Events()
              return
            }

            let diff = obj.getTimeDiff(app.tx.invert(d3event.x))
            obj.event.addTimeDiff(diff)
            app.updateEvent(obj.event).then(() => {
              app.updateD3Diffs()
              app.updateD3Events()
            })
          }

          function onGroupDragEnd (d3event, obj) {
            if (app.isSnapShotView) {
              return
            }
            app.dragXtoTime = null
            // reset event-box
            d3.select(this).attr('transform', 'translate(0,0)')
            app.altKeyPressed ? duplicationGroup(obj) : setGroupPos(obj)
          }

          function duplicationGroup (e) {
            let _group = []
            let newGroupId = uuid4()

            let ev = [...app.events]
            ev.sort((a, b) => a.begin - b.begin)

            ev.forEach(_e => {
              if (e.group === _e.group) {
                let x = app.tx(_e.begin) + e.translate_x_cache
                let diff = _e.getTimeDiff(app.tx.invert(x))
                _e.addTimeDiff(diff)
                let copy = _e.copy()
                copy.group = newGroupId
                _group.push(copy) // push to api
              }
            })
            // update refs | set uuids
            for (let i = 0; i < _group.length; i++) {
              _group[i].prev_ref = i > 0 ? _group[i - 1].uuid : null
              _group[i].next_ref = i < _group.length - 1 ? _group[i + 1].uuid : null
            }
            app.$root.$emit('copyGroup', _group)
          }

          function setGroupPos (e) {
            // apply time diffs to events in group
            let promises = []
            if (e.group) {
              app.events.forEach(_e => {
                if (_e.group === e.group) {
                  let x = app.tx(_e.begin) + e.translate_x_cache
                  let diff = _e.getTimeDiff(app.tx.invert(x))
                  _e.addTimeDiff(diff)
                  promises.push(app.updateEvent(_e)) // push to api
                }
              })
            } else {
              let x = app.tx(e.event.begin) + e.translate_x_cache
              let diff = e.event.getTimeDiff(app.tx.invert(x))
              e.event.addTimeDiff(diff)
              promises.push(app.updateEvent(e.event)) // push to api
            }
            Promise.all(promises).then(() => {
              app.updateD3Diffs()
              app.updateD3Events() // push to api
            })
          }

          function onDelete (e) {
            app.$bvModal.msgBoxConfirm(
              app.$t('ems.timeline.track.deleteQuestion'),
              {
                title: app.$t('ems.timeline.track.delete'),
                okVariant: 'danger',
                okTitle: app.$t('ems.timeline.ok'),
                cancelTitle: app.$t('ems.timeline.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }
            )
              .then(value => {
                if (value) {
                  let promises = []
                  if (e.group) {
                    app.events.forEach(_e => {
                      if (_e.group === e.group) {
                        app.deleteD3Event(_e)
                        promises.push(app.deleteEvent(_e))
                      }
                    })
                  } else {
                    app.deleteD3Event(e)
                    promises.push(app.deleteEvent(e.event))
                  }
                  Promise.all(promises).then(_e => {
                    app.updateTrack()
                    app.makeToast('OK! Group deleted')
                  })
                }
              })
          }
          resolve()
        })
      },

      updateNowIndication () {
        if (!this.tx) {
          return
        }
        let now = this.tx(moment())
        if (!this.nowIndication) {
          this.nowIndication = this.trackSvg.append('g')
            .attr('transform', 'translate(' + now + ' , 0)')

          this.nowIndication.append('line')
            .attr('y2', '100%')
            .attr('stroke-width', 1)
            .attr('stroke', '#ff5700')

          this.nowIndication.append('rect')
            .attr('fill', '#ff5700')
            .attr('height', 12)
            .attr('width', 46)
            .attr('y', 1)

          this.nowIndication.append('text')
            .attr('x', 5)
            .attr('y', 10)
            .style('font-size', '9px')
            .style('fill', '#fff').text(moment().format('HH:mm:ss'))
        } else {
          this.nowIndication
            .transition()
            .attr('transform', 'translate(' + now + ' , 0)')
            .style('z-index', 999)
            .select('text').text(moment().format('HH:mm:ss'))

          // bring on top
          this.nowIndication.raise()
        }
      }
    },

    mounted () {
      this.setupD3Scale().then(() => {
        this.getEvents().then(res => {
          this.events = []
          // store events
          res.msg.result.forEach(e => {
            this.events.push(new Event(e))
          })
          this.clearD3Events()
          this.getDataSet().then(res => {
            this.updateD3Diffs(res)
            this.addD3Events(res).then(() => {
              this.setInitZoom()
            })
          })
        })
      })
      this.trackSvg
        .on('mouseover', () => { this.hovered = true })
        .on('mouseout', () => { this.hovered = false })
    }
  }
</script>

<style lang="scss" scoped>
  .track-header {
    background-color: #e9ecef;
  }

  .track-wrap {
    position: relative;
  }

  .loading-wrap, .zoom-lock {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #004085;
    background: #cce5ffbd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .zoom-lock {
    background: transparent;
    span {
      padding: 2px 5px;
      color: #fff;
      background: #212529;
      border: 3px solid #ff5700;
      font-size: 12px;
    }
  }

  svg.track {
    background-color: #f9f9f9;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
