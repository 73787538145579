/*
 * Copyright (C) 2019. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import { RRule, RRuleSet } from 'rrule'
import uuid4 from 'uuid4'
import moment from 'moment'

// TODO : make random color persistent with db field
// const COLORS = [
//   '#FC9F5B', '#70D6FF', '#D496A7', '#20A39E', '#FFCB47',
//   '#EAC435', '#345995', '#03CEA4', '#FB4D3D', '#CA1551',
//   '#29BF12', '#ABFF4F', '#03CEA4', '#F21B3F', '#FF9914'
// ]

const COLORS = ['#70D6FF']

export default class Event {
  constructor (metaData) {
    this._metaData = metaData
    this._d3events = []
    this._rruleSet = null
    this._uicolor = COLORS[Math.floor(Math.random() * COLORS.length)]

    this._nextDiff = null
    this._prevDiff = null

    this._rruleSet = metaData.rrules
      ? new RRule(RRule.parseString(metaData.rrules))
      : null
  }

  getTimeDiff (time) {
    return moment(time).diff(this.begin)
  }

  addTimeDiff (diff) {
    this.begin = moment(this.begin).add(diff)

    this.d3events.forEach(e => {
      e.begin = moment(e.begin).add(diff)
    })

    if (this._rruleSet) {
      this.updateRRuleSet()
    }
  }

  updateRRuleSet () {
    let rrule = RRule.parseString(this._rruleSet.toString())
    rrule.dtstart = this.begin.toDate()
    this._rruleSet = new RRuleSet()
    this._rruleSet.rrule(new RRule(rrule))
  }

  get metaData () {
    return this._metaData
  }

  set metaData (meta) {
    this._metaData = meta
  }

  get prevRef () {
    return this._metaData.prev_ref
  }

  get nextRef () {
    return this._metaData.next_ref
  }

  get nextDiff () {
    return this._nextDiff
  }

  set nextDiff (diff) {
    this._nextDiff = diff
  }

  get prevDiff () {
    return this._prevDiff
  }

  set prevDiff (diff) {
    this._prevDiff = diff
  }

  get uuid () {
    return this._metaData.uuid
  }

  get begin () {
    return moment(this._metaData.begin)
  }

  set begin (time) {
    this._metaData.begin = moment(time)
  }

  get method () {
    return this._metaData.method
  }

  get args () {
    return this._metaData.args
  }

  set args (args) {
    this._metaData.args = args
  }

  get argsMixins () {
    return this._metaData.args_mixins
  }

  set argsMixins (argsMixins) {
    this._metaData.args_mixins = argsMixins
  }

  get trackUuid () {
    return this._metaData.track_uuid
  }

  get clientUuid () {
    return this._metaData.client_uuid
  }

  get group () {
    return this._metaData.group
  }

  get rruleSet () {
    return this._rruleSet
  }

  set rruleSet (rruleSet) {
    this._rruleSet = rruleSet
  }

  get durationFixed () {
    return 'duration_fixed' in this.argsMixins &&
      this.argsMixins['duration_fixed']
  }

  get d3events () {
    return this._d3events
  }

  get uiColor () {
    return this._uicolor
  }

  get hasCmsArgs () {
    return this.args && 'uuid' in this.args
  }

  get cmsLink () {
    return '/emsweb/content/en/clientdata/' + this.args.uuid
  }

  copy () {
    let meta = Object.assign({}, this.metaData)
    meta.uuid = uuid4()
    meta.broker_uid = null
    meta.next_ref = null
    meta.prev_ref = null
    meta.rrules = null
    // very important: recreate rrules
    if (this.rruleSet) {
      let rrule = RRule.parseString(this._rruleSet.toString())
      rrule.dtstart = this.begin.utc().toDate()
      let rruleSet = new RRuleSet()
      rruleSet.rrule(new RRule(rrule))
      meta.rrules = rruleSet.toString()
    } else {
      meta.rrules = null
    }
    console.debug('copy_meta: ', meta)
    return meta
  }

  addD3Event (d3event) {
    this._d3events.push(d3event)
  }

  getRuleSetEvents (from, till) {
    if (this._rruleSet) {
      let dates = []
      this._rruleSet.between(from.toDate(), till.toDate(), true).forEach(d => {
        dates.push(moment(d))
      })
      return dates
    } else if (this.begin.isBetween(from, till, null, '[]')) {
      return [this.begin]
    }
    return []
  }
}
